import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material/material.module';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/inicio/inicio.module').then((m) => m.InicioModule)
    }
];

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserAnimationsModule, SharedModule, MaterialModule, RouterModule.forRoot(routes)],
    bootstrap: [AppComponent]
})
export class AppModule {}
