import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NOMBRE_EMPRESA } from './modules/shared/components/constants/identidad';
import { ModalInicioComponent } from './modules/inicio/components/modal-inicio/modal-inicio.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(public dialog: MatDialog) {}
    title = 'Prodej MX';
    public nombreEmpresa = NOMBRE_EMPRESA;

    static leerBandera(): boolean {
        const valorStorageBanderaMostrar = localStorage.getItem('banderaNoMostrar');
        return valorStorageBanderaMostrar ? JSON.parse(valorStorageBanderaMostrar) : false;
    }

    onActivate(event: any): void {
        window.scroll(0, 0);
    }

    ngOnInit(): void {
        const banderaNoMostrar = AppComponent.leerBandera();
        const config = { data: banderaNoMostrar };
        const dialogRef = this.dialog.open(ModalInicioComponent, config);
        dialogRef.afterClosed().subscribe();
    }
}
