<div class="bottom_header dark_skin main_menu_uppercase border-top">
    <div class="container">
        <div class="row align-items-center">
            <!--            <div class="col-lg-3 col-md-4 col-sm-6 col-3">
                <div class="categories_wrap">
                    <button
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navCatContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        class="categories_btn categories_menu"
                    >
                        <span>Todas las categorías </span><i class="linearicons-menu"></i>
                    </button>
                    <div id="navCatContent" class="navbar collapse">
                        <ul>
                            <li class="dropdown dropdown-mega-menu">
                                <a
                                    class="dropdown-item nav-link dropdown-toggler"
                                    href="#"
                                    data-toggle="dropdown"
                                    ><i class="flaticon-tv"></i> <span>Computadoras</span></a
                                >
                                <div class="dropdown-menu">
                                    <ul class="mega-menu d-lg-flex">
                                        <li class="mega-menu-col col-lg-7">
                                            <ul class="d-lg-flex">
                                                <li class="mega-menu-col col-lg-6">
                                                    <ul>
                                                        <li class="dropdown-header">Featured Item</li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vestibulum sed</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec porttitor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae facilisis</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Curabitur tempus</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vivamus in tortor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae ante ante</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Etiam ac rutrum</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Quisque condimentum</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="mega-menu-col col-lg-6">
                                                    <ul>
                                                        <li class="dropdown-header">Popular Item</li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Curabitur laoreet</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vivamus in tortor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae facilisis</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Quisque condimentum</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Etiam ac rutrum</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae ante ante</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec porttitor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Curabitur tempus</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="mega-menu-col col-lg-5">
                                            <div class="header-banner2">
                                                <img
                                                    src="../../../../../assets/images/menu_banner7.jpg"
                                                    alt="menu_banner1"
                                                />
                                                <div class="banne_info">
                                                    <h6>10% Off</h6>
                                                    <h4>Computers</h4>
                                                    <a href="#">Shop now</a>
                                                </div>
                                            </div>
                                            <div class="header-banner2">
                                                <img
                                                    src="../../../../../assets/images/menu_banner8.jpg"
                                                    alt="menu_banner2"
                                                />
                                                <div class="banne_info">
                                                    <h6>15% Off</h6>
                                                    <h4>Top Laptops</h4>
                                                    <a href="#">Shop now</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="dropdown dropdown-mega-menu">
                                <a
                                    class="dropdown-item nav-link dropdown-toggler"
                                    href="#"
                                    data-toggle="dropdown"
                                    ><i class="flaticon-responsive"></i> <span>Tabletas y celulares</span></a
                                >
                                <div class="dropdown-menu">
                                    <ul class="mega-menu d-lg-flex">
                                        <li class="mega-menu-col col-lg-7">
                                            <ul class="d-lg-flex">
                                                <li class="mega-menu-col col-lg-6">
                                                    <ul>
                                                        <li class="dropdown-header">Featured Item</li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vestibulum sed</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec porttitor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae facilisis</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Curabitur tempus</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vivamus in tortor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae ante ante</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Etiam ac rutrum</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="mega-menu-col col-lg-6">
                                                    <ul>
                                                        <li class="dropdown-header">Popular Item</li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Curabitur laoreet</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vivamus in tortor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae facilisis</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Quisque condimentum</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Etiam ac rutrum</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae ante ante</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec porttitor</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="mega-menu-col col-lg-5">
                                            <div class="header-banner2">
                                                <a href="#"
                                                    ><img
                                                        src="../../../../../assets/images/menu_banner6.jpg"
                                                        alt="menu_banner"
                                                /></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="dropdown dropdown-mega-menu">
                                <a
                                    class="dropdown-item nav-link dropdown-toggler"
                                    href="#"
                                    data-toggle="dropdown"
                                    ><i class="flaticon-camera"></i> <span>Cámaras</span></a
                                >
                                <div class="dropdown-menu">
                                    <ul class="mega-menu d-lg-flex">
                                        <li class="mega-menu-col col-lg-7">
                                            <ul class="d-lg-flex">
                                                <li class="mega-menu-col col-lg-6">
                                                    <ul>
                                                        <li class="dropdown-header">Featured Item</li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vestibulum sed</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec porttitor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae facilisis</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Curabitur tempus</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vivamus in tortor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae ante ante</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Etiam ac rutrum</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li class="mega-menu-col col-lg-6">
                                                    <ul>
                                                        <li class="dropdown-header">Popular Item</li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Curabitur laoreet</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Vivamus in tortor</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae facilisis</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Quisque condimentum</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Etiam ac rutrum</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec vitae ante ante</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item nav-link nav_item"
                                                                href="#"
                                                                >Donec porttitor</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="mega-menu-col col-lg-5">
                                            <div class="header-banner2">
                                                <a href="#"
                                                    ><img
                                                        src="../../../../../assets/images/menu_banner9.jpg"
                                                        alt="menu_banner"
                                                /></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="dropdown dropdown-mega-menu">
                                <a
                                    class="dropdown-item nav-link dropdown-toggler"
                                    href="#"
                                    data-toggle="dropdown"
                                    ><i class="flaticon-plugins"></i> <span>Accesorios</span></a
                                >
                                <div class="dropdown-menu">
                                    <ul class="mega-menu d-lg-flex">
                                        <li class="mega-menu-col col-lg-4">
                                            <ul>
                                                <li class="dropdown-header">Woman's</li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-list-left-sidebar.html"
                                                        >Vestibulum sed</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-left-sidebar.html"
                                                        >Donec porttitor</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-right-sidebar.html"
                                                        >Donec vitae facilisis</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-list.html"
                                                        >Curabitur tempus</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-load-more.html"
                                                        >Vivamus in tortor</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="mega-menu-col col-lg-4">
                                            <ul>
                                                <li class="dropdown-header">Men's</li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-cart.html"
                                                        >Donec vitae ante ante</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="checkout.html"
                                                        >Etiam ac rutrum</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="wishlist.html"
                                                        >Quisque condimentum</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="compare.html"
                                                        >Curabitur laoreet</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="order-completed.html"
                                                        >Vivamus in tortor</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="mega-menu-col col-lg-4">
                                            <ul>
                                                <li class="dropdown-header">Kid's</li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail.html"
                                                        >Donec vitae facilisis</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail-left-sidebar.html"
                                                        >Quisque condimentum</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail-right-sidebar.html"
                                                        >Etiam ac rutrum</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail-thumbnails-left.html"
                                                        >Donec vitae ante ante</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail-thumbnails-left.html"
                                                        >Donec porttitor</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <a class="dropdown-item nav-link nav_item" href="coming-soon.html"
                                    ><i class="flaticon-headphones"></i> <span>Audifonos</span></a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item nav-link nav_item" href="404.html"
                                    ><i class="flaticon-console"></i> <span>Juegos</span></a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item nav-link nav_item" href="coming-soon.html"
                                    ><i class="flaticon-monitor"></i> <span>TV & Smart Box</span></a
                                >
                            </li>
                            <li>
                                <a class="dropdown-item nav-link nav_item" href="404.html"
                                    ><i class="flaticon-printer"></i> <span>Impresoras</span></a
                                >
                            </li>
                            <li>
                                <ul class="more_slide_open">
                                    <li>
                                        <a class="dropdown-item nav-link nav_item" href="login.html"
                                            ><i class="flaticon-fax"></i> <span>Fax Machine</span></a
                                        >
                                    </li>
                                    <li>
                                        <a class="dropdown-item nav-link nav_item" href="register.html"
                                            ><i class="flaticon-mouse"></i> <span>Mouse</span></a
                                        >
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div class="more_categories">Más categorias</div>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</div>
