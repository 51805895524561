<div class="modal-dialog modal-lg modal-dialog-centered p-0 m-0" role="document">
    <div class="modal-content">
        <div class="modal-body">
            <button
                type="button"
                class="close"
                (click)="cerrarDialogoManual()"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true"><i class="ion-ios-close-empty"></i></span>
            </button>
            <div class="row no-gutters align-items-center">
                <div class="d-none d-sm-inline col-sm-5">
                    <img
                        src="../../../../../assets/images/popup_img.png"
                        class=""
                        alt="Imagen avi{on de papel"
                    />
                </div>
                <div class="col-sm-7">
                    <div class="popup_content p-2 pt-3">
                        <div class="popup-text">
                            <div class="heading_s1">
                                <h4>Suscríbete para obtener grandes descuentos</h4>
                            </div>
                            <p>
                                Suscríbete a nuestro boletín para recibir actualizaciones acerca de nuestros
                                productos.
                            </p>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Correo electrónico</mat-label>
                                <input
                                    id="mailInputId"
                                    name="email"
                                    type="email"
                                    placeholder="ejemplo@correo.com"
                                    [formControl]="correoUsuario"
                                    matInput
                                />
                                <mat-error *ngIf="correoUsuario.invalid">
                                    No parece un correo válido
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <button
                                *ngIf="isSaving; else noSaving"
                                class="btn btn-fill-line btn-block text-uppercase rounded-0"
                                title="Subscribe"
                                type="button"
                            >
                                <div class="spinner-border text-light" role="status"></div>
                            </button>
                            <ng-template #noSaving>
                                <button
                                    (click)="onSubmit()"
                                    class="btn btn-fill-line btn-block text-uppercase rounded-0"
                                    title="Subscribe"
                                    type="button"
                                >
                                    Suscribirme
                                </button>
                            </ng-template>
                        </div>
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name="checkbox"
                                    id="exampleCheckbox3"
                                    value=""
                                    [formControl]="checkNostrar"
                                />
                                <label class="form-check-label" for="exampleCheckbox3"
                                    ><span>¡No mostrarme este mensaje de nuevo!</span></label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
