import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { delay, mapTo, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NewsLetterService {
    constructor(private httpClient: HttpClient) {}

    public registerMail(mail: string): Observable<boolean> {
        const params = new HttpParams().append('dest', mail);
        return this.httpClient
            .get<any>('https://us-central1-prodej-front.cloudfunctions.net/sendMail', {
                params,
                responseType: 'text' as 'json'
            })
            .pipe(mapTo(true));
    }
}
