<div class="top-header light_skin bg_dark d-none d-md-block">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-8">
        <div class="header_topbar_info">
          <div class="header_offer">
            <span>Free Ground Shipping Over $250</span>
          </div>
          <div class="download_wrap">
            <span class="mr-3">Download App</span>
            <ul class="icon_list text-center text-lg-left">
              <li>
                <a href="#"><i class="fab fa-apple"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-android"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-windows"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-4">
        <div
          class="d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <div class="lng_dropdown">
            <select name="countries" class="custome_select">
              <option
                value="en"
                data-image="../../../../../assets/images/eng.png"
                data-title="English"
              >
                English
              </option>
              <option
                value="fn"
                data-image="../../../../../assets/images/fn.png"
                data-title="France"
              >
                France
              </option>
              <option
                value="us"
                data-image="../../../../../assets/images/us.png"
                data-title="United States"
              >
                United States
              </option>
            </select>
          </div>
          <div class="ml-3">
            <select name="countries" class="custome_select">
              <option value="USD" data-title="USD">USD</option>
              <option value="EUR" data-title="EUR">EUR</option>
              <option value="GBR" data-title="GBR">GBR</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
