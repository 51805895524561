<footer class="bg_gray">
    <div class="footer_top small_pt pb_20">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12 col-sm-12">
                    <div class="widget">
                        <div class="footer_logo">
                            <a routerLink="/"
                                ><img
                                    class="logo_header"
                                    src="../../../../../assets/images/logo/logo_footer.png"
                                    alt="logo"
                            /></a>
                        </div>
                        <p class="mb-3">
                            Empresa 100% mexicana con la misión de dar soporte a pequeñas y medianas empresas
                            en la digitalización de sus negocios y procesos
                        </p>
                        <ul class="contact_info">
                            <li>
                                <i class="ti-location-pin"></i>
                                <p>República de Uruguay 13, Col. Centro, Alc. Cuauhtemoc, CDMX</p>
                            </li>
                            <li>
                                <i class="ti-email"></i>
                                <a href="mailto:info@sitename.com">contacto@prodej.com.mx</a>
                            </li>
                            <li>
                                <i class="ti-mobile"></i>
                                <p>5516141928</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <div class="widget">
                        <h6 class="widget_title">Ligas útiles</h6>
                        <ul class="widget_links">
                            <li><a routerLink="/nosotros">Acerca de nosotros</a></li>
                            <!--                            <li><a routerLink="/faqs">Pregutas frecuentes</a></li>-->
                            <!--                            <li><a href="#">Location</a></li>-->
                            <!--                            <li><a href="#">Affiliates</a></li>-->
                            <li><a routerLink="/contacto">Contáctanos</a></li>
                        </ul>
                    </div>
                </div>
                <!--                <div class="col-lg-2 col-md-4 col-sm-6">
                    <div class="widget">
                        <h6 class="widget_title">Mi cuenta</h6>
                        <ul class="widget_links">
                            <li><a href="#">Mi cuenta</a></li>
                            <li><a href="#">Descuentos</a></li>
                            <li><a href="#">Devoluciones</a></li>
                            <li><a href="#">Historial de compras</a></li>
                            <li><a href="#">Entregas en proceso</a></li>
                        </ul>
                    </div>
                </div>-->
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <!--                    <div class="widget">-->
                    <!--                        <h6 class="widget_title">Download App</h6>-->
                    <!--                        <ul class="app_list">-->
                    <!--                            <li>-->
                    <!--                                <a href="#"><img src="../../../../../assets/images/f1.png" alt="f1" /></a>-->
                    <!--                            </li>-->
                    <!--                            <li>-->
                    <!--                                <a href="#"><img src="../../../../../assets/images/f2.png" alt="f2" /></a>-->
                    <!--                            </li>-->
                    <!--                        </ul>-->
                    <!--                    </div>-->
                    <div class="widget">
                        <h6 class="widget_title">Social</h6>
                        <ul class="social_icons">
                            <li>
                                <a
                                    href="https://www.facebook.com/ProdejMX"
                                    target="_blank"
                                    class="sc_facebook"
                                    ><i class="ion-social-facebook"></i
                                ></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/Prodej_MX" target="_blank" class="sc_twitter"
                                    ><i class="ion-social-twitter"></i
                                ></a>
                            </li>
                            <!--                            <li>-->
                            <!--                                <a href="#" class="sc_google"><i class="ion-social-googleplus"></i></a>-->
                            <!--                            </li>-->
                            <!--                            <li>
                                <a href="#" class="sc_youtube"><i class="ion-social-youtube-outline"></i></a>
                            </li>
                            <li>
                                <a href="#" class="sc_instagram"
                                    ><i class="ion-social-instagram-outline"></i
                                ></a>
                            </li>-->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom_footer border-top-tran">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="text-center text-md-left mb-md-0">© 2021 Prodej cómputo</p>
                </div>
                <div class="col-lg-6">
                    <ul class="footer_payment text-center text-md-right">
                        <li>
                            <a href="#"><img src="../../../../../assets/images/visa.png" alt="visa" /></a>
                        </li>
                        <!--                        <li>
                            <a href="#"
                                ><img src="../../../../../assets/images/discover.png" alt="discover"
                            /></a>
                        </li>-->
                        <li>
                            <a href="#"
                                ><img src="../../../../../assets/images/master_card.png" alt="master_card"
                            /></a>
                        </li>
                        <li>
                            <a href="#"><img src="../../../../../assets/images/paypal.png" alt="paypal" /></a>
                        </li>
                        <li>
                            <a href="#"
                                ><img
                                    src="../../../../../assets/images/amarican_express.png"
                                    alt="amarican_express"
                            /></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
