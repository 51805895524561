import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TopbarComponent } from './components/header/topbar/topbar.component';
import { MiddleHeaderComponent } from './components/header/middle-header/middle-header.component';
import { BottomHeaderComponent } from './components/header/bottom-header/bottom-header.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

// const SHARED_MODULES = [];
const SHARED_COMPONENTS = [HeaderComponent, FooterComponent];

@NgModule({
    declarations: [...SHARED_COMPONENTS, TopbarComponent, MiddleHeaderComponent, BottomHeaderComponent],
    exports: [...SHARED_COMPONENTS],
    imports: [RouterModule, HttpClientModule]
})
export class SharedModule {}
