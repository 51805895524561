<div class="bottom_header dark_skin main_menu_uppercase">
    <div class="container">
        <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" routerLink="">
                <img class="logo_light" src="../../../../../../assets/images/logo_light.png" alt="logo" />
                <img
                    class="logo_header"
                    src="../../../../../../assets/images/logo/logo_header.png"
                    alt="logo"
                />
            </a>
            <button
                class="navbar-toggler button-toggler side_navbar_toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSidetoggle"
                aria-expanded="false"
            >
                <span class="ion-android-menu"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end mobile_side_menu" id="navbarSidetoggle">
                <ul class="navbar-nav">
                    <li class="d-flex justify-content-start">
                        <button
                            class="navbar-toggler navbar-toggler-expanded side_navbar_toggler mx-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSidetoggle"
                            aria-expanded="false"
                        >
                            <span class="ion-android-menu"></span>
                        </button>
                    </li>
                    <li class="dropdown" routerLinkActive="active">
                        <a data-toggle="dropdown" class="nav-link nav_item" routerLink="/home">Inicio</a>
                    </li>
                    <!--                    <li class="dropdown dropdown-mega-menu">
                        <a class="dropdown-toggle nav-link" href="#" data-toggle="dropdown">Tienda</a>
                        <div class="dropdown-menu">
                            <ul class="mega-menu d-lg-flex">
                                <li class="mega-menu-col col-lg-9">
                                    <ul class="d-lg-flex">
                                        <li class="mega-menu-col col-lg-4">
                                            <ul>
                                                <li class="dropdown-header">Shop Page Layout</li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-list.html"
                                                        >shop List view</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-list-left-sidebar.html"
                                                        >shop List Left Sidebar</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-list-right-sidebar.html"
                                                        >shop List Right Sidebar</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-left-sidebar.html"
                                                        >Left Sidebar</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-right-sidebar.html"
                                                        >Right Sidebar</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-load-more.html"
                                                        >Shop Load More</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="mega-menu-col col-lg-4">
                                            <ul>
                                                <li class="dropdown-header">Other Pages</li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-cart.html"
                                                        >Cart</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="checkout.html"
                                                        >Checkout</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="my-account.html"
                                                        >My Account</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="wishlist.html"
                                                        >Wishlist</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="compare.html"
                                                        >compare</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="order-completed.html"
                                                        >Order Completed</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="mega-menu-col col-lg-4">
                                            <ul>
                                                <li class="dropdown-header">Páginas de productos</li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail.html"
                                                        >Default</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail-left-sidebar.html"
                                                        >Left Sidebar</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail-right-sidebar.html"
                                                        >Right Sidebar</a
                                                    >
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item nav-link nav_item"
                                                        href="shop-product-detail-thumbnails-left.html"
                                                        >Thumbnails Left</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="mega-menu-col col-lg-3">
                                    <div class="header_banner">
                                        <div class="header_banner_content">
                                            <div class="shop_banner">
                                                <div class="banner_img overlay_bg_40">
                                                    <img
                                                        src="../../../../../assets/images/shop_banner4.jpg"
                                                        alt="shop_banner2"
                                                    />
                                                </div>
                                                <div class="shop_bn_content">
                                                    <h6 class="text-uppercase shop_subtitle">
                                                        Nueva colección
                                                    </h6>
                                                    <h5 class="text-uppercase shop_title">
                                                        30% de descuento
                                                    </h5>
                                                    <a
                                                        href="#"
                                                        class="btn btn-white rounded-0 btn-xs text-uppercase"
                                                        >Comprar ahora</a
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>-->
                    <!--                    <li class="dropdown">
                        <a class="dropdown-toggle nav-link" data-toggle="dropdown">Páginas</a>
                        <div class="dropdown-menu">
                            <ul>
                                <li>
                                    <a class="dropdown-item nav-link nav_item" href="term-condition.html"
                                        >Términos y condiciones</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </li>-->
                    <li routerLinkActive="active">
                        <a class="nav-link nav_item" routerLink="/nosotros">Nosotros</a>
                    </li>
                    <!--                    <li>
                        <a class="dropdown-item nav-link nav_item" routerLink="/faqs">Preguntas frecuentes</a>
                    </li>-->
                    <li routerLinkActive="active">
                        <a class="nav-link nav_item item-menu" routerLink="/contacto">Contáctanos</a>
                    </li>
                </ul>
            </div>
            <!--            <ul class="navbar-nav attr-nav align-items-center">
                <li>
                    <a href="#" class="nav-link"><i class="linearicons-user"></i></a>
                </li>
                <li>
                    <a href="#" class="nav-link"
                        ><i class="linearicons-heart"></i><span class="wishlist_count">0</span></a
                    >
                </li>
                <li class="dropdown cart_dropdown">
                    <a class="nav-link cart_trigger" href="#" data-toggle="dropdown"
                        ><i class="linearicons-bag2"></i><span class="cart_count">2</span
                        ><span class="amount"><span class="currency_symbol">$</span>159.00</span></a
                    >
                    <div class="cart_box cart_right dropdown-menu dropdown-menu-right">
                        <ul class="cart_list">
                            <li>
                                <a href="#" class="item_remove"><i class="ion-close"></i></a>
                                <a href="#"
                                    ><img
                                        src="../../../../../assets/images/cart_thamb1.jpg"
                                        alt="cart_thumb1"
                                    />Variable product 001</a
                                >
                                <span class="cart_quantity">
                                    1 x
                                    <span class="cart_amount"> <span class="price_symbole">$</span></span
                                    >78.00</span
                                >
                            </li>
                            <li>
                                <a href="#" class="item_remove"><i class="ion-close"></i></a>
                                <a href="#"
                                    ><img
                                        src="../../../../../assets/images/cart_thamb2.jpg"
                                        alt="cart_thumb2"
                                    />Ornare sed consequat</a
                                >
                                <span class="cart_quantity">
                                    1 x
                                    <span class="cart_amount"> <span class="price_symbole">$</span></span
                                    >81.00</span
                                >
                            </li>
                        </ul>
                        <div class="cart_footer">
                            <p class="cart_total">
                                <strong>Subtotal:</strong>
                                <span class="cart_price"> <span class="price_symbole">$</span></span
                                >159.00
                            </p>
                            <p class="cart_buttons">
                                <a href="#" class="btn btn-fill-line view-cart">View Cart</a
                                ><a href="#" class="btn btn-fill-out checkout">Checkout</a>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>-->
        </nav>
    </div>
</div>
