import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NewsLetterService } from '../../../shared/services/news-letter.service';

@Component({
    templateUrl: './modal-inicio.component.html',
    styleUrls: ['./modal-inicio.component.scss']
})
export class ModalInicioComponent implements OnInit, OnDestroy {
    constructor(
        private newsLetterService: NewsLetterService,
        public dialogRef: MatDialogRef<ModalInicioComponent>,
        @Inject(MAT_DIALOG_DATA) public data: boolean
    ) {}
    public checkNostrar = new FormControl(null);
    private mailRegExp = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
    public correoUsuario = new FormControl(null, [Validators.pattern(this.mailRegExp), Validators.required]);
    private subscriptions: Subscription[] = [];
    private guardandoCorreo = false;
    public isSaving = false;

    static saveLocalStorage(v: boolean): void {
        localStorage.setItem('banderaNoMostrar', JSON.stringify(v));
    }

    ngOnInit(): void {
        this.checkNostrar.setValue(this.data);
        const obsCheckMostrar$ = this.checkNostrar.valueChanges.pipe(
            tap(ModalInicioComponent.saveLocalStorage)
        );
        this.subscriptions.push(obsCheckMostrar$.subscribe());
    }

    public onSubmit(): void {
        if (this.correoUsuario.valid) {
            const correo: string = this.correoUsuario.value;
            this.guardandoCorreo = true;
            this.isSaving = true;
            this.newsLetterService.registerMail(correo).subscribe((_) => {
                this.guardandoCorreo = false;
                this.dialogRef.close();
            });
        }
    }
    public cerrarDialogoManual(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
